import { getStrapiImage, getStrapiImagePlaceholder } from '@/lib/api'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import SupervisedUserCircleSharp from '@mui/icons-material/SupervisedUserCircleSharp'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'

const BlogDetail: React.FC<{ data: Article }> = ({ data }) => {
  const { back } = useRouter()
  return (
    <Container sx={{ my: { xs: 2, md: 6 } }}>
      <Stack
        sx={{
          width: 1,
          minHeight: { xs: 250, md: 500 },
          bgcolor: 'primary.main',
          position: 'relative',
        }}
      >
        <Image
          src={getStrapiImage(data?.media)}
          blurDataURL={getStrapiImagePlaceholder(data?.media)}
          placeholder="blur"
          alt={data?.media?.alternativeText}
          objectFit="cover"
          layout="fill"
        />
      </Stack>
      <Stack spacing={2} sx={{ width: { sx: 1, md: 0.7 }, mx: 'auto', my: 6 }}>
        <Typography variant="h4" fontWeight={700} gutterBottom>
          {data?.title}
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Stack direction="row" spacing={2}>
            <SupervisedUserCircleSharp color="primary" fontSize="small" />
            <Typography fontWeight={700}>By Admin</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <CalendarTodayIcon color="primary" fontSize="small" />
            <Typography fontWeight={700}>
              {dayjs(data?.publishedAt).locale('fr').format('DD MMMM YYYY')}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          color="text.secondary"
          textAlign="justify"
          gutterBottom
          className="prose"
          component="div"
          dangerouslySetInnerHTML={{
            __html: data?.content,
          }}
        />
      </Stack>
      <Stack alignItems="center">
        <Button
          variant="contained"
          color="primary"
          disableElevation
          startIcon={<ArrowBackIcon />}
          onClick={back}
          sx={{ px: 6, py: 2, borderRadius: 50 }}
        >
          {`Liste d'articles`}
        </Button>
      </Stack>
    </Container>
  )
}

export default BlogDetail
