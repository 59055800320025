import { getStrapiImage, getStrapiImagePlaceholder } from '@/lib/api'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import SupervisedUserCircleSharp from '@mui/icons-material/SupervisedUserCircleSharp'
import { Divider } from '@mui/material'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import Image from 'next/image'
import Link from 'next/link'

interface Props {
  data: BlogCard
}

const BlogCard: React.FC<Props> = ({ data }) => {
  return (
    <Link href={`/blog/${data?.slug}`} passHref>
      <Paper
        elevation={4}
        sx={{ boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)', cursor: 'pointer' }}
      >
        <Stack
          sx={{
            width: 1,
            minHeight: 240,
            bgcolor: 'primary.main',
            position: 'relative',
          }}
        >
          <Image
            src={getStrapiImage(data?.media)}
            blurDataURL={getStrapiImagePlaceholder(data?.media)}
            placeholder="blur"
            alt={data?.media?.alternativeText}
            objectFit="cover"
            layout="fill"
          />
        </Stack>
        <Stack spacing={2} sx={{ p: 2 }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            spacing={2}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <SupervisedUserCircleSharp color="primary" fontSize="small" />
              <Typography fontWeight={700}>By Admin</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <CalendarTodayIcon color="primary" fontSize="small" />
              <Typography fontWeight={700}>
                {dayjs(data?.publishedAt).locale('fr').format('DD MMMM YYYY')}
              </Typography>
            </Stack>
          </Stack>
          <Link href={`/blog/${data?.slug}`} passHref>
            <Typography
              variant="h6"
              fontWeight={700}
              sx={{
                display: '-webkit-box!important',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipse',
                whiteSpace: 'normal',
                '&:hover': {
                  color: 'primary.main',
                  cursor: 'pointer',
                },
              }}
            >
              {data?.title}
            </Typography>
          </Link>
          <Typography
            variant="body2"
            color="text.primary"
            gutterBottom
            sx={{
              display: '-webkit-box!important',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipse',
              whiteSpace: 'normal',
            }}
          >
            {data?.description}
          </Typography>
          <Divider />
          <Link href={`/blog/${data?.slug}`} passHref>
            <Button color="primary" endIcon={<ArrowForwardIcon />}>
              En savoir plus
            </Button>
          </Link>
        </Stack>
      </Paper>
    </Link>
  )
}

export default BlogCard
